<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Settings</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-card>
            <v-toolbar flat dense dark color="accent">
              <v-toolbar-title>Website Functionality Toggles</v-toolbar-title>
            </v-toolbar>
            <v-simple-table>
              <tbody>
                <tr>
                  <th>Venues Can Be Booked?</th>
                  <td>
                    <v-switch
                      inset
                      color="green"
                      v-model="can_book_venues"
                      :label="can_book_venues ? 'Yes' : 'No'"
                      :loading="can_book_venues_loading"
                      @click="updateSetting('can_book_venues')"
                    ></v-switch>
                  </td>
                </tr>
                <tr>
                  <th>Annual Passes Can Be Purchased?</th>
                  <td class="text-right">
                    <v-switch
                      inset
                      color="green"
                      v-model="can_purchase_annual_passes"
                      :label="can_purchase_annual_passes ? 'Yes' : 'No'"
                      :loading="can_purchase_annual_passes_loading"
                      @click="updateSetting('can_purchase_annual_passes')"
                    ></v-switch>
                  </td>
                </tr>
                <tr>
                  <th>Show Navigation Message?</th>
                  <td class="text-right">
                    <v-switch
                      inset
                      color="green"
                      v-model="show_navigation_message"
                      :label="show_navigation_message ? 'Yes' : 'No'"
                      :loading="show_navigation_message_loading"
                      @click="updateSetting('show_navigation_message')"
                    ></v-switch>
                  </td>
                </tr>
                <tr>
                  <th>Navigation Message</th>
                  <td class="pt-2 pb-2">
                    <simple-tiptap v-model="navigation_message"></simple-tiptap>
                    <v-btn
                      color="success"
                      class="mt-2"
                      depressed
                      small
                      :loading="navigation_message_loading"
                      @click="updateNavigationMessage"
                      >Save</v-btn
                    >
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SimpleTiptap from "@/views/components/SimpleTiptap.vue";

export default {
  components: {
    SimpleTiptap,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Settings",
          disabled: true,
        },
      ],
      can_book_venues: false,
      can_book_venues_loading: false,
      can_purchase_annual_passes: false,
      can_purchase_annual_passes_loading: false,
      show_navigation_message: false,
      show_navigation_message_loading: false,
      navigation_message: "",
      navigation_message_loading: false,
    };
  },

  computed: {
    settings() {
      return this.$store.getters["craigtoun/settingsStore/all"];
    },
  },

  mounted() {
    if (this.settings.some((s) => s.name === "can_book_venues")) {
      const setting = this.settings.find((s) => s.name === "can_book_venues");

      this.can_book_venues = setting.content == "1" ? true : false;
    }

    if (this.settings.some((s) => s.name === "can_purchase_annual_passes")) {
      const setting = this.settings.find(
        (s) => s.name === "can_purchase_annual_passes"
      );

      this.can_purchase_annual_passes = setting.content == "1" ? true : false;
    }

    if (this.settings.some((s) => s.name === "show_navigation_message")) {
      const setting = this.settings.find(
        (s) => s.name === "show_navigation_message"
      );

      this.show_navigation_message = setting.content == "1" ? true : false;
    }

    if (this.settings.some((s) => s.name === "navigation_message")) {
      const setting = this.settings.find(
        (s) => s.name === "navigation_message"
      );

      this.navigation_message = setting.content;
    }
  },

  methods: {
    updateSetting(name) {
      const appId = this.$route.params.id;
      this[name + "_loading"] = true;

      this.$store
        .dispatch("craigtoun/settingsStore/updateSettings", {
          appId,
          fields: {
            name,
            content: this[name] ? "1" : "0",
          },
        })
        .then(() => (this[name + "_loading"] = false));
    },

    updateNavigationMessage() {
      const appId = this.$route.params.id;
      this.navigation_message_loading = true;

      this.$store
        .dispatch("craigtoun/settingsStore/updateSettings", {
          appId,
          fields: {
            name: "navigation_message",
            content: this.navigation_message,
          },
        })
        .then(() => {
          this.navigation_message_loading = false;
        });
    },
  },
};
</script>
